import React from "react";
import { Card, Grid } from "@material-ui/core";
import { url } from "../../../../Routes/Urls";
import { ListGroupsForUser, ListUsers } from "../../../../Util/AdminQuerries";
import { getUser, getCustomer } from "../../../../graphql/queries";
import { getUserStatusText } from "../../../../Util/Util";
import { GetItemById } from "../../../../Util/API";
import { Roles } from "../../../../Util/Const";
import RoundButton from "../../../../Components/RoundButton";
import RoundButtonLink from "../../../../Components/RoundButtonLink";
import { PersonAdd } from "@material-ui/icons";
import { checkAutherized } from "../../../../Routes/Urls";
import Auth from "@aws-amplify/auth";

const acceptedRoles = [Roles.admin.value, Roles.customerAdmin.value]; //Global admin and Customer admin

class SectionUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autherized: checkAutherized(props.role, acceptedRoles, props.history),
      customer: props.location.state?.customer,
      users: [],
      loading: true,
    };

    this.fetchCustomer = this.fetchCustomer.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
  }
  async componentDidMount() {
    if (this.state.autherized) {
      if (this.state.customer == null) {
        try {
          let user = await GetItemById(this.props.userID, getUser);
          let customer = await GetItemById(user.customerID, getCustomer);
          this.setState({ customer });
          this.fetchUsers(customer);
        } catch (err) {
          console.error(err);
        }
      } else {
        this.fetchUsers(this.state.customer);
      }
    }
  }

  async fetchUsers(customer) {
    try {
      let allUsers = [];
      let cognitoUsers = (await ListUsers())?.Users;

      allUsers = cognitoUsers.map((user) => {
        let newUser = { userStatus: user.UserStatus };
        for (let attribute of user.Attributes) {
          newUser[attribute.Name] = attribute.Value;
        }
        return newUser;
      });
      let customersUsers = [];
      if (customer?.Users?.items != null && customer?.Users?.items.length > 0) {
        for (let user of customer.Users.items) {
          let userToAdd = allUsers.find((u) => u.sub === user.id);
          if (userToAdd != null) {
            userToAdd.role = (
              await ListGroupsForUser(userToAdd.sub)
            ).Groups[0].GroupName;
            customersUsers.push(userToAdd);
          }
        }
        this.setState({ users: customersUsers });
      }
    } catch (err) {
      console.error(err);
    }

    this.setState({ loading: false });
  }

  async fetchCustomer() {}

  render() {
    if (!this.state.autherized) {
      return null;
    }
    if (this.state.loading) {
      return <p>Loading</p>;
    }
    return (
      <>
        <h1>Användare hos {this.state?.customer?.name}</h1>

        <div style={{ display: "flex", marginBottom: "40px" }}>
          <RoundButton
            style={{ marginRight: "20px" }}
            onClick={() => this.props.history.goBack()}
          >
            <p>Gå tillbaka</p>
          </RoundButton>
          <RoundButtonLink
            to={{
              pathname: url.adminCustomersUsersEdit,
              state: { customer: this.state.customer },
            }}
          >
            <PersonAdd></PersonAdd>
            <p>Lägg till ny användare</p>
          </RoundButtonLink>
        </div>
        {this.state.users.length === 0 && <p>Inga Användare</p>}
        <Grid container spacing={3}>
          {this.state.users.map((user) => (
            <Grid item key={user.sub} xs={12} md={12}>
              <Card style={{ padding: "20px" }}>
                <p>{user?.name}</p>
                <p>E-post: {user?.email}</p>
                <p>Status: {getUserStatusText(user.userStatus)}</p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "40px",
                  }}
                >
                  {this.props.role === Roles.admin.value && (
                    <RoundButtonLink
                      style={{ marginRight: "20px" }}
                      to={{
                        pathname: url.adminCustomersUsersEdit,
                        state: {
                          customer: this.state.customer,
                          userId: user.sub,
                        },
                      }}
                    >
                      <p>Redigera</p>
                    </RoundButtonLink>
                  )}

                  <RoundButtonLink
                    to={{
                      pathname: url.userWeeklyOverview,
                      state: {
                        user: { id: user.sub, name: user.name || user.email },
                      },
                    }}
                  >
                    <p> Visa svar</p>
                  </RoundButtonLink>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
}

export default SectionUsers;
