import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { API, graphqlOperation } from 'aws-amplify';
import React from 'react'
import { deleteCathegory, deleteQuestion, updateCathegory } from '../../../../graphql/mutations';
import { listForms, getCathegory } from '../../../../graphql/queries';
import Question from './Question'
import { PopupConfirm, PopupError } from '../../../../Util/Util';


class Cathegory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cathegoryText: this.props.cathegory.cathegory,
            edit: false,
        }
        this.editCathegory = this.editCathegory.bind(this)
        this.checkIfCathegoryIsUsed = this.checkIfCathegoryIsUsed.bind(this)
        this.deleteTheCathegory = this.deleteTheCathegory.bind(this)
        this.cancel = this.cancel.bind(this)
    }

    async editCathegory() {
        if (this.state.edit) {
            try {
                const newCathegory = { ...this.props.cathegory, cathegory: this.state.cathegoryText }
                const updatedCathegory =
                {
                    id: newCathegory.id,
                    cathegory: newCathegory.cathegory,
                    isArchived: newCathegory.isArchived
                }
                await API.graphql(graphqlOperation(updateCathegory, { input: updatedCathegory }))
                this.setState({ edit: false })
                this.props.reload();
            } catch (err) {
                PopupError("Kunde inte spara kategorin")
                console.error('error when updating cathegory', err)
            }
        } else {
            this.setState({ edit: true })
        }
    }
    //A candel button for the user to abort if entered the editing mode
    cancel() {
        this.setState({ edit: false, cathegoryText: this.props.cathegory.cathegory })
    }

    //This is to check so that non of the existing forms are using the cathegory.
    async checkIfCathegoryIsUsed() {
        if (!this.state.edit) {
            try {
                let tempForms = (await API.graphql(graphqlOperation(listForms)))?.data.listForms.items;
                let form;
                for (let i = 0; i < tempForms.length; i++) {
                    form = tempForms[i].cathegories.items.find(cathegory => cathegory.cathegoryID === this.props.cathegory.id)
                    if (form) {
                        PopupError("Kategorin används i existerande forumlär. Kommer inte att raderas");
                        break;
                    }
                }
                if (!form) {
                    PopupConfirm("Är du säker på att du vill ta bort kategorin och alla dess tillhörande frågor?", ()=> this.deleteTheCathegory())}
            } catch (err) {
                PopupError("Fel uppstod vid borttagning av kategori");
                console.error("error fetching forms;", err);
            }

        }
    }
    //Should the cathegory not be used in any active forms, this function is called.
    //It deletes every question from the database (even archived ones) and finally the cathegory itself.
    async deleteTheCathegory() {
        try {
            const retrievedCathegory = (await API.graphql(graphqlOperation(getCathegory, { id: this.props.cathegory.id })))?.data.getCathegory;
            for (let i = 0; i < retrievedCathegory.questions.items.length; i++) {
                try {
                    await API.graphql({ query: deleteQuestion, variables: { input: { id: retrievedCathegory.questions.items[i].id } } });
                } catch (error) {
                    console.error("Could not delete question from cathegory", error)
                    window.alert("Fel uppstod vid borttagning av frågor från kategori")
                }
            }
            try {
                await API.graphql({ query: deleteCathegory, variables: { input: { id: retrievedCathegory.id } } });
            } catch (error) {
                console.error("Cathegory could not be deleted", error)
                window.alert("Fel uppstod vid borttagning av kategori")
            }
        this.props.reload();
        } catch (error) {
            console.error("Cathegory could not be fetced during deletion", error)
            window.alert("Fel uppstod vid hämting av kategori inför borttagningen av kategorin")
        }
    }

    //Only show the questions that are "not archived".
    render() {
        return (
            <div>
                <div className="tableRow tableCathegory">
                    {!this.state.edit && <h4 >{this.props.cathegory.cathegory}</h4>}

                    {this.state.edit &&
                        <input id={this.props.cathegory.id}
                            className="tableRowInput"
                            value={this.state.cathegoryText}
                            placeholder={this.props.cathegory.cathegory}
                            onChange={event => this.setState({ cathegoryText: event.target.value })}
                        ></input>
                    }
                    <div>
                        {!this.state.edit && <Button startIcon={<EditIcon />} variant="contained" color="default" onClick={this.editCathegory} >Redigera</Button>}
                        {this.state.edit && <Button startIcon={<SaveIcon />} variant="contained" color="default" onClick={this.editCathegory} >Save</Button>}
                        {!this.state.edit && <Button startIcon={<DeleteIcon />} variant="contained" color="default" onClick={this.checkIfCathegoryIsUsed}>Ta bort</Button>}
                        {this.state.edit && <Button startIcon={<CloseIcon />} variant="contained" color="default" onClick={this.cancel}>Avbryt</Button>}
                    </div>

                </div>
                {
                    this.props.cathegory.questions.items?.sort(( a, b) => a.order > b.order? 1 : b.order > a.order ? -1 : 0 ).map((question) => question.isArchived ? < React.Fragment key={question.id} /> :
                        (<Question key={question.id} question={question} reload={this.props.reload} />))
                }
            </div>
        );
    }
}
export default Cathegory;