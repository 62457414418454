import React from "react";
import { API } from "aws-amplify";
import { getForm, getCathegory } from "../../graphql/queries";
import { updateAnswer, updateForm } from "../../graphql/mutations";
import UserAgreement from "./Components/UserAgreement";
import Victory from "./Components/Victory";
import { Grid, Card, withStyles } from "@material-ui/core";
import RoundButton from "../../Components/RoundButton";
import StarRating from "../../Components/StarRating";
import { MWGreen } from "../../Util/Const";

const steps = [
  "Användaravtal",
  "Upplever och ambitionsnivå",
  "Prioritering",
  "Sammanställning",
];
//Todo: add classes to steps to make them green.
const styles = (theme) => ({
  stepIcon: {
    color: MWGreen,
  },
});

class UserView extends React.Component {
  constructor(props) {
    super(props);

    const formID = this.props.match.params?.id;
    if (formID == null || formID === "") {
      //Todo: handle
      console.error("invalid id", props.location.pathname);
      // this.props.history.goBack();
    }
    this.state = {
      formID,
      form: null,
      cathegories: [],
      answers: [],
      step: "",
      loaded: false,
    };
    this.updateAnswer = this.updateAnswer.bind(this);
    this.save = this.save.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }
  componentDidMount() {
    this.fetchForm();
  }

  //Where we fetch the selected user form
  async fetchForm() {
    try {
      const tempForm = (
        await API.graphql({
          query: getForm,
          variables: { id: this.state.formID },
          authMode: "AWS_IAM",
        })
      )?.data.getForm;

      const info = await this.getFormInformation(tempForm);
      this.setState({
        form: tempForm,
        step: tempForm.step,
        loaded: true,
        ...info,
      });
    } catch (err) {
      console.error("error fetching form;", err);
    }
  }
  //Retrieve the cathegories in the from
  async getFormInformation(form) {
    let cathegories = [];
    for (let i = 0; i < form.cathegories.items.length; i++) {
      cathegories.push({
        ...(await this.getCathegorybyID(form.cathegories.items[i].cathegoryID)),
        order: form.cathegories.items[i].order,
      });
    }
    return { cathegories: cathegories, answers: form.answers.items };
  }
  //Retrieve the cathegories from the DB
  async getCathegorybyID(id) {
    return (
      await API.graphql({
        query: getCathegory,
        variables: { id },
        authMode: "AWS_IAM",
      })
    )?.data?.getCathegory;
  }

  async save() {
    try {
      if (!this.state.form?.giveAway) {
        for (let i = 0; i < this.state.answers.length; i++) {
          const updatedAnswer = {
            id: this.state.answers[i].id,
            formID: this.state.answers[i].formID,
            questionID: this.state.answers[i].questionID,
            today: this.state.answers[i].today,
            want: this.state.answers[i].want,
            priority: this.state.answers[i].priority,
          };
          await API.graphql({
            query: updateAnswer,
            variables: { input: updatedAnswer },
            authMode: "AWS_IAM",
          });
        }
      }
      this.nextStep();
    } catch (error) {
      console.error("Error when updatig answers", error);
    }
  }
  //User wants to continue to next step of form
  async nextStep() {
    let nextStep = this.state.step;
    if (nextStep < 3) {
      nextStep = nextStep + 1;

      let tempForm = this.state.form;
      try {
        if (this.state.form?.giveAway) {
          this.setState({
            form: {
              ...this.state.form,
              answers: { items: this.state.answers },
            },
          });
        } else {
          const updatedForm = {
            id: tempForm.id,
            receiver: tempForm.receiver,
            step: nextStep,
          };
          const newForm = (
            await API.graphql({
              query: updateForm,
              variables: { input: updatedForm },
              authMode: "AWS_IAM",
            })
          )?.data?.updateForm;

          this.setState({ form: newForm });
        }
        this.setState({ step: nextStep });
      } catch (error) {
        console.error("Could not update Form step", error);
      }
    }
  }
  //If the user wants to move backwards in form navigation
  async previousStep() {
    let prevStep = this.state.step;
    //They can not regret the user agreement(this because it is saved in DB as approved if step > 0)
    if (prevStep >= 1) {
      prevStep = prevStep - 1;
      this.setState({ step: prevStep });
    }
  }

  updateAnswer(changedAnswer) {
    let arrayIndex = this.state.answers.findIndex(
      (answer) => answer.id === changedAnswer.id
    );
    let newArray = [...this.state.answers];
    newArray[arrayIndex] = {
      ...newArray[arrayIndex],
      today: changedAnswer.today,
    };
    newArray[arrayIndex] = {
      ...newArray[arrayIndex],
      want: changedAnswer.want,
    };
    newArray[arrayIndex] = {
      ...newArray[arrayIndex],
      priority: changedAnswer.priority,
    };
    this.setState({ answers: newArray });
  }

  getStep() {
    switch (this.state.step) {
      case 0:
        return <UserAgreement giveAway={this.state.form?.giveAway || false} />;
      case 1:
      case 2:
        return (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} >
                <Card style={{ padding: "20px" }}>
                  {this.state.step === 1 && (
                    <p>
                      <b>A</b>: Hur upplever jag det här område idag?
                    </p>
                  )}
                  {this.state.step === 1 && (
                    <p>
                      <b>B</b>: Hur skulle jag vilja att det var?
                    </p>
                  )}
                  {this.state.step === 2 && (
                    <p>
                      <b>C</b>: Hur mycket är jag beredd att göra för att få
                      till en förändring?
                    </p>
                  )}
                </Card>
              </Grid>
              {this.state.cathegories
                .sort((a, b) =>
                  a.order > b.order ? 1 : b.order > a.order ? -1 : 0
                )
                .map((cathegory) => (
                  <Grid item xs={12} key={cathegory.id}>
                    <Card style={{ padding: "20px" }}>
                      <h3>
                        <b>{cathegory.cathegory}</b>
                      </h3>

                      {cathegory.questions.items
                        .map((question) => {
                          let answer = this.state.answers.find(
                            (answer) => answer.questionID === question.id
                          );
                          //To filter questions that was added to the cathegory after the form was created
                          if (answer == null) {
                            return null;
                          }
                          if (this.state.step === 1) {
                            return (
                              <div key={question.id}>
                                <p>
                                  <b>{question.question}</b>
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p style={{ paddingRight: "10px" }}>
                                    A <br /> Idag
                                  </p>
                                  <StarRating
                                    id={answer.id + "today"}
                                    stars={answer.today}
                                    max={10}
                                    size={"small"}
                                    onChange={(newValue) =>
                                      this.updateAnswer({
                                        ...answer,
                                        today: newValue,
                                      })
                                    }
                                    noClear={true}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p style={{ paddingRight: "10px" }}>
                                    B <br /> Vill
                                  </p>
                                  <StarRating
                                    id={answer.id + "want"}
                                    stars={answer.want}
                                    size={"small"}
                                    max={10}
                                    onChange={(newValue) =>
                                      this.updateAnswer({
                                        ...answer,
                                        want: newValue,
                                      })
                                    }
                                    noClear={true}
                                  />
                                </div>
                              </div>
                            );
                          }
                          if (this.state.step === 2) {
                            return (
                              <div key={question.id}>
                                <p>
                                  <b>{question.question}</b>
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p style={{ paddingRight: "10px" }}>
                                    C <br /> Prio
                                  </p>
                                  <StarRating
                                    id={answer.id + "priority"}
                                    stars={answer.priority}
                                    max={10}
                                    size="small"
                                    onChange={(newValue) =>
                                      this.updateAnswer({
                                        ...answer,
                                        priority: newValue,
                                      })
                                    }
                                    noClear={true}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </>
        );

      case 3:
        return (
          <Victory
            showText={true}
            form={this.state.form}
            cathegories={this.state.cathegories}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { classes } = this.props;

    if (!this.state.loaded) {
      return <p>Laddar ...</p>;
    } else {
      return (
        <>
          <Grid container>
            <Grid item xs={12}>
              <h3>
                Steg {this.state.step + 1 + "/" + steps.length}:{" "}
                {steps[this.state.step]}
              </h3>
            </Grid>
            {this.getStep()}

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "40px",
                }}
              >
                {this.state.step > 0 && (
                  <div style={{ marginRight: "20px" }}>
                    <RoundButton onClick={this.previousStep}>
                      <p>Bakåt</p>
                    </RoundButton>
                  </div>
                )}
                {this.state.step < 3 && (
                  <RoundButton onClick={this.save}>
                    <p>Fortsätt</p>
                  </RoundButton>
                )}
              </div>
            </Grid>
          </Grid>
        </>
      );
    }
  }
}
export default withStyles(styles)(UserView);
