import { Auth } from "aws-amplify";
import React from "react";
import Router from "./Routes/Router";
import { AuthenticatedUrls, UnauthenticatedUrls } from "./Routes/Urls";
import { withRouter } from "react-router-dom";
import { Roles } from "./Util/Const";
import Navbar from "./Components/Navbar";
import Loading from "./Components/Loading";
import "./style.css";
import HeaderBar from "./Components/HeaderBar";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: null,
      isAuthenticated: false,
      role: Roles.customer.value,
      isLoading: true,
    };
    this.userSignIn = this.userSignIn.bind(this);
    this.userSignOut = this.userSignOut.bind(this);
    this.testIfLogin = this.testIfLogin.bind(this);
  }
  componentDidMount() {
    this.testIfLogin();
  }

  async userSignIn() {
    try {
      let userID = (await Auth.currentUserInfo())?.username;

      if (userID) {
        this.setState({
          isAuthenticated: true,
          userID: userID,
          role: await this.GetCurrentUserGroup(),
        });

      }
    } catch (err) {
      console.error("SignIn:", err);
    }
  }
  async userSignOut() {
    try {
      await Auth.signOut();
      this.setState({ isAuthenticated: false });
      this.props.history.push({ pathname: UnauthenticatedUrls.login.path });
    } catch (err) {
      console.error("Singout:", err);
    }
  }
  async testIfLogin() {
    try {
      let userID = (await Auth.currentUserInfo())?.username;
      if (userID) {
        this.setState({
          isAuthenticated: true,
          userID: userID,
          role: await this.GetCurrentUserGroup(),
          isLoading: false,
        });
        if (this.props.location.pathname === "/" || this.props.location.pathname === UnauthenticatedUrls.login.path) {
          this.props.history.push({ pathname: AuthenticatedUrls.home.path });
        }
        return;
      }
    } catch (err) {
      console.error("SignIn:", err);
    }
    this.setState({ isLoading: false });
  }
  async GetCurrentUserGroup() {
    let user = await Auth.currentAuthenticatedUser();
    let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    return groups[0];
  }

  render() {
    const childProps = {
      app: {
        isAuthenticated: this.state.isAuthenticated,
        userID: this.state.userID,
        role: this.state.role,
        history: this.props.history,
        
        userSignIn: this.userSignIn,
        userSignOut: this.userSignOut,
      },
    };
    if (this.state.isLoading) {
      return <Loading />;
    } else {
      return (
        <div>
          {this.state.isAuthenticated && <Navbar app={childProps.app} />}
          {!this.state.isAuthenticated && <HeaderBar />}
          <div className="mainContainer">
            <div className="pageContainer">
              {/* <div style={{ width: "100%" }}> */}
              <Router childProps={childProps} />
              {/* </div> */}
              <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}><p>version 1.2</p></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(App);
