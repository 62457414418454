/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cognito_identity_pool_id": "eu-north-1:f71f1471-4750-4d49-8843-eb946482a49f",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_l8uLaTr8A",
    "aws_user_pools_web_client_id": "4q4ior89s1a9la4c9jdm87bk5g",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://rzfogi55tfayvecudb7x232ani.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://4wzweuxzpb.execute-api.eu-north-1.amazonaws.com/prod",
            "region": "eu-north-1"
        }
    ],
    "aws_user_files_s3_bucket": "mwcoachingc9b4feda92164835a3901d0f4d9f9d28124848-prod",
    "aws_user_files_s3_bucket_region": "eu-north-1",
    "aws_content_delivery_bucket": "mwcoaching-20210330132117-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-north-1",
    "aws_content_delivery_url": "https://d2spw04wya5rkk.cloudfront.net"
};


export default awsmobile;
