import API, { graphqlOperation } from "@aws-amplify/api";
import { Card, Grid, TextField } from "@material-ui/core";
import React from "react";
import { Component } from "react";
import RoundButton from "../../../../Components/RoundButton";
import {
  updateAgreement,
  createAgreement,
} from "../../../../graphql/mutations";
import { listAgreements } from "../../../../graphql/queries";
import { PopupError } from "../../../../Util/Util";
import { Roles } from "../../../../Util/Const";
import { checkAutherized } from "../../../../Routes/Urls";

const acceptedRoles = [Roles.admin.value]; //Global admin
const initialState = { text: "", giveAwayText: "" };

class AgreementEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //So user can not use the URL and bypass router authentication
      autherized: checkAutherized(props.role, acceptedRoles, props.history),
      agreement: initialState,
      text: "",
      giveAwayText: "",
    };
    this.fetchAgreement = this.fetchAgreement.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    //Dont load data if not autherized
    if (this.state.autherized) {
      this.fetchAgreement();
    }
  }
  //Retrieve the agreement from DB. 
  //If there is nothing in the DB, then create a new, empty agreement.
  //If there are more than 1 agreement, Error.
  //If there is something in the DB, then use it.
  async fetchAgreement() {
    try {
      let agreement = (await API.graphql(graphqlOperation(listAgreements)))
        ?.data?.listAgreements?.items;

      //If there are nothing in the DB, make a new agreement, then fetch it.
      if (agreement == null || agreement.length === 0) {
        let createNewAgreement = {
          text: this.state.text,
          giveAwayText: this.state.giveAwayText,
        };
        await API.graphql(graphqlOperation(createAgreement, { input: createNewAgreement }));
        let agreement = (await API.graphql(graphqlOperation(listAgreements)))?.data?.listAgreements?.items;
        this.setState({
          agreement: agreement[0],
          text: agreement[0].text,
          giveAwayText: agreement[0].giveAwayText,
        });
      //There should only be 1 row of Agreeement in the Database!
      } else if (agreement.length > 1) {
        PopupError("Multiple copies of Agreements in database!");
      }
      //If there is something in the DB, use it
       if (agreement != null && agreement.length > 0) {
        this.setState({
          agreement: agreement[0],
          text: agreement[0].text,
          giveAwayText: agreement[0].giveAwayText,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async save() {
    let updatedAgreement = {
      id: this.state.agreement.id,
      text: this.state.text,
      giveAwayText: this.state.giveAwayText,
    };
    try {
      await API.graphql(
        graphqlOperation(updateAgreement, { input: updatedAgreement })
      );
      this.props.history.goBack();
    } catch (error) {
      console.error(error);
      PopupError("Kunde inte spara användaravtal");
    }
  }

  render() {
    if (!this.state.autherized) {
      return null;
    }
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <h1>Användaravtal</h1>
            <Card style={{ padding: "20px", marginBottom: "20px" }}>
              <h2>Användaravtal vanligt</h2>
              <TextField
                id="standard-multiline-textAvtal"
                label="Användaravtal"
                multiline
                fullWidth
                rowsMax="30"
                value={this.state.text}
                onChange={(event) =>
                  this.setState({ text: event.target.value })
                }
                //   className={classes.textField}
                margin="normal"
              />
            </Card>
            <Card style={{ padding: "20px" }}>
              <h2>Användaravtal give away</h2>
              <TextField
                id="standard-multiline-giveAwayAvtal"
                label="Användaravtal give away"
                multiline
                fullWidth
                rowsMax="30"
                value={this.state.giveAwayText}
                onChange={(event) =>
                  this.setState({ giveAwayText: event.target.value })
                }
                //   className={classes.textField}
                margin="normal"
              />
            </Card>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "40px",
              }}
            >
              <RoundButton onClick={this.save}>
                <p>Spara avtal</p>
              </RoundButton>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default AgreementEdit;
