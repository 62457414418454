import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import { listForms } from "../../../../graphql/queries";
import {
  deleteAnswer,
  deleteFormCathegory,
  deleteForm,
} from "../../../../graphql/mutations";
import { Card, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PostAdd from "@material-ui/icons/PostAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  url,
  checkAutherized,
} from "../../../../Routes/Urls";
import RoundButtonLink from "../../../../Components/RoundButtonLink";
import IconButton from "../../../../Components/IconButton";
import { PopupConfirm } from "../../../../Util/Util";
import Empty from "../../../../Components/Empty";
import { Roles } from "../../../../Util/Const";

const acceptedRoles = [Roles.admin.value]; //Global admin

class FormsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //So user can not use the URL and bypass router authentication
      autherized: checkAutherized(props.role, acceptedRoles, props.history),
      forms: [],
      newReceiver: "",
    };
  }

  componentDidMount() {
    //Dont load data if not autherized
    if (this.state.autherized) {
      this.fetchForms();
    }
  }
  createUserLink(id) {
    const currentUrl = window.location.href.replace(url.adminForm, "");
    return currentUrl + url.form + id;
  }

  async fetchForms() {
    try {
      let tempForms = (await API.graphql(graphqlOperation(listForms)))?.data
        .listForms.items;

      //order by alpabetical
      tempForms.sort((a, b) =>
        a.receiver > b.receiver ? 1 : b.receiver > a.receiver ? -1 : 0
      );
      this.setState({ forms: tempForms });
    } catch (err) {
      console.error("error fetching forms;", err);
    }
  }
  //Delete the entire form with its answers and connections to the different cathegories in the DB
  async deleteEntireForm(formID) {
    let toBeDeletedForm = this.state.forms.find((form) => form.id === formID);
    await this.deleteAnswers(toBeDeletedForm);
    await this.deleteFormCathegory(toBeDeletedForm);
    await this.deleteForm(toBeDeletedForm);
    await this.fetchForms();
  }
  //Delete the answers from DB
  async deleteAnswers(toBeDeletedForm) {
    try {
      for (let i = 0; i < toBeDeletedForm.answers.items.length; i++) {
        await API.graphql({
          query: deleteAnswer,
          variables: { input: { id: toBeDeletedForm.answers.items[i].id } },
        });
      }
    } catch (error) {
      console.error("Error deleting answer", error);
    }
  }
  //Delete the connections the form has to added cathegories from DB
  async deleteFormCathegory(toBeDeletedForm) {
    try {
      for (let i = 0; i < toBeDeletedForm.cathegories.items.length; i++) {
        await API.graphql({
          query: deleteFormCathegory,
          variables: { input: { id: toBeDeletedForm.cathegories.items[i].id } },
        });
      }
    } catch (error) {
      console.error("Could not delete formCathegory connection", error);
    }
  }
  //Delete the form from DB
  async deleteForm(toBeDeletedForm) {
    try {
      await API.graphql({
        query: deleteForm,
        variables: { input: { id: toBeDeletedForm.id } },
      });
    } catch (error) {
      console.error("Could not delete form", error);
    }
  }

  render() {
    if (!this.state.autherized) {
      return null;
    }
    return (
      <>
        <h1>Livshjulet Formulär</h1>
        <Grid container spacing={3}>
          {this.state.forms.length === 0 && (
            <Grid item xs={12} md={4}>
              <Empty />
            </Grid>
          )}
          {this.state.forms.map((form) => (
            <Grid item xs={12} md={4} key={form.id}>
              <Card style={{ padding: "20px" }}>
                <div>
                  <p>
                    <b>{form.receiver}</b>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {!form?.giveAway && (
                    <div style={{ width: "60px", marginRight: "40px" }}>
                      <IconButton
                        onClick={() =>
                          this.props.history.push({
                            pathname: url.adminView,
                            state: { formID: form.id },
                          })
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </div>
                  )}
                  {form?.giveAway && (
                    <p style={{ marginRight: "40px" }}>Give Away visas ej</p>
                  )}
                  <a href={this.createUserLink(form.id)}>Länk</a>
                </div>

                <div>
                  <p>Skapad</p>
                  <p>{new Date(form.createdAt).toDateString()}</p>
                </div>

                <div style={{ width: "60px" }}>
                  <IconButton
                    onClick={() =>
                      PopupConfirm(
                        "Är du säker på att du vill ta bort formuläret?",
                        () => this.deleteEntireForm(form.id)
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>

        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "40px" }}
        >
          <div style={{ marginRight: "20px" }}>
            <RoundButtonLink to={url.createForm}>
              <PostAdd />
              <p>Skapa nytt formulär</p>
            </RoundButtonLink>
          </div>
          <RoundButtonLink to={url.userAgreementEdit}>
            <p>Användaravtal</p>
          </RoundButtonLink>
        </div>
      </>
    );
  }
}

export default FormsList;
