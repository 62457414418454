import React from "react";
import { API } from "aws-amplify";
import { listCustomers } from "../../../../graphql/queries";
import { url, checkAutherized } from "../../../../Routes/Urls";
import { Card, Grid } from "@material-ui/core";
import Image from "../../../../Components/Image";
import RoundButtonLink from "../../../../Components/RoundButtonLink";
import { PersonAdd } from "@material-ui/icons";
import { Roles } from "../../../../Util/Const";

const acceptedRoles = [Roles.admin.value]; //Global admin

class SectionCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //So user can not use the URL and bypass router authentication
      autherized: checkAutherized(props.role, acceptedRoles, props.history),
      customers: [],
    };

    this.fetchCustomers = this.fetchCustomers.bind(this);
  }

  componentDidMount() {
    if (this.state.autherized) {
      this.fetchCustomers();
    }
  }

  async fetchCustomers() {
    let res = await API.graphql({ query: listCustomers });
    this.setState({ customers: res.data.listCustomers.items });
  }

  render() {
    if (!this.state.autherized) {
      return null;
    }
    return (
      <>
        <h1>Kunder</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "40px",
          }}
        >
          <RoundButtonLink to={{ pathname: url.adminCustomerEdit }}>
            <PersonAdd />
            <p>Lägg till ny kund</p>
          </RoundButtonLink>
        </div>
        <Grid container spacing={3}>
          {this.state.customers.map((customer) => (
            <Grid item key={customer.id} xs={12} md={6}>
              <Card style={{ padding: "30px" }}>
                <div>
                  <h2>{customer.name}</h2>
                  <div style={{ width: "200px", height: "200px" }}>
                    <Image image={customer.imagePath} />
                  </div>
                </div>

                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <RoundButtonLink
                    to={{
                      pathname: url.adminCustomersUsers,
                      state: { customer },
                    }}
                  >
                    <p>Användare</p>
                  </RoundButtonLink>
                </div>
                <div>
                  <RoundButtonLink
                    to={{
                      pathname: url.adminCustomerEdit,
                      state: { customer },
                    }}
                  >
                    <p>Redigera</p>
                  </RoundButtonLink>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
}

export default SectionCustomers;
