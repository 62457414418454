/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      imagePath
      Users {
        items {
          id
          customerID
          createdAt
          updatedAt
        }
        nextToken
      }
      subscriptionStartDate
      subscriptinoEndDate
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        imagePath
        Users {
          items {
            id
            customerID
            createdAt
            updatedAt
          }
          nextToken
        }
        subscriptionStartDate
        subscriptinoEndDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      customerID
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDailyQuestion = /* GraphQL */ `
  query GetDailyQuestion($id: ID!) {
    getDailyQuestion(id: $id) {
      id
      isReflection
      Question
      createdAt
      updatedAt
    }
  }
`;
export const listDailyQuestions = /* GraphQL */ `
  query ListDailyQuestions(
    $filter: ModelDailyQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isReflection
        Question
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDailyGoal = /* GraphQL */ `
  query GetDailyGoal($id: ID!) {
    getDailyGoal(id: $id) {
      id
      userID
      priority
      toDo
      reflection
      goodReflection
      badReflection
      lessonLearned
      done
      createdAt
      updatedAt
    }
  }
`;
export const listDailyGoals = /* GraphQL */ `
  query ListDailyGoals(
    $filter: ModelDailyGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        priority
        toDo
        reflection
        goodReflection
        badReflection
        lessonLearned
        done
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDailyAnswer = /* GraphQL */ `
  query GetDailyAnswer($id: ID!) {
    getDailyAnswer(id: $id) {
      id
      questionDailyID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const listDailyAnswers = /* GraphQL */ `
  query ListDailyAnswers(
    $filter: ModelDailyAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionDailyID
        userID
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWeekly = /* GraphQL */ `
  query GetWeekly($id: ID!) {
    getWeekly(id: $id) {
      id
      userID
      goal
      important
      help
      strategyWorkOut
      good
      bad
      solution
      reflection
      createdAt
      updatedAt
    }
  }
`;
export const listWeeklys = /* GraphQL */ `
  query ListWeeklys(
    $filter: ModelWeeklyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        goal
        important
        help
        strategyWorkOut
        good
        bad
        solution
        reflection
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      cathegoryID
      question
      order
      isArchived
      createdAt
      updatedAt
      cathegory {
        id
        cathegory
        isArchived
        createdAt
        updatedAt
        questions {
          items {
            id
            cathegoryID
            question
            order
            isArchived
            createdAt
            updatedAt
            cathegory {
              id
              cathegory
              isArchived
              createdAt
              updatedAt
              questions {
                items {
                  id
                  cathegoryID
                  question
                  order
                  isArchived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cathegoryID
        question
        order
        isArchived
        createdAt
        updatedAt
        cathegory {
          id
          cathegory
          isArchived
          createdAt
          updatedAt
          questions {
            items {
              id
              cathegoryID
              question
              order
              isArchived
              createdAt
              updatedAt
              cathegory {
                id
                cathegory
                isArchived
                createdAt
                updatedAt
                questions {
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listCathegorys = /* GraphQL */ `
  query ListCathegorys(
    $filter: ModelCathegoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCathegorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cathegory
        isArchived
        createdAt
        updatedAt
        questions {
          items {
            id
            cathegoryID
            question
            order
            isArchived
            createdAt
            updatedAt
            cathegory {
              id
              cathegory
              isArchived
              createdAt
              updatedAt
              questions {
                items {
                  id
                  cathegoryID
                  question
                  order
                  isArchived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCathegory = /* GraphQL */ `
  query GetCathegory($id: ID!) {
    getCathegory(id: $id) {
      id
      cathegory
      isArchived
      createdAt
      updatedAt
      questions {
        items {
          id
          cathegoryID
          question
          order
          isArchived
          createdAt
          updatedAt
          cathegory {
            id
            cathegory
            isArchived
            createdAt
            updatedAt
            questions {
              items {
                id
                cathegoryID
                question
                order
                isArchived
                createdAt
                updatedAt
                cathegory {
                  id
                  cathegory
                  isArchived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      receiver
      step
      giveAway
      createdAt
      updatedAt
      cathegories {
        items {
          id
          cathegoryID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          id
          formID
          questionID
          today
          want
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        receiver
        step
        giveAway
        createdAt
        updatedAt
        cathegories {
          items {
            id
            cathegoryID
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        answers {
          items {
            id
            formID
            questionID
            today
            want
            priority
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFormCathegory = /* GraphQL */ `
  query GetFormCathegory($id: ID!) {
    getFormCathegory(id: $id) {
      id
      cathegoryID
      order
      createdAt
      updatedAt
    }
  }
`;
export const listFormCathegorys = /* GraphQL */ `
  query ListFormCathegorys(
    $filter: ModelFormCathegoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormCathegorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cathegoryID
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      formID
      questionID
      today
      want
      priority
      createdAt
      updatedAt
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        formID
        questionID
        today
        want
        priority
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAgreement = /* GraphQL */ `
  query GetAgreement($id: ID!) {
    getAgreement(id: $id) {
      id
      text
      giveAwayText
      createdAt
      updatedAt
    }
  }
`;
export const listAgreements = /* GraphQL */ `
  query ListAgreements(
    $filter: ModelAgreementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgreements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        giveAwayText
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
